var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading.codes)?_c('div',{staticClass:"h-100 d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_vm._e(),_c('BackBtn'),_c('BreadCrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('h1',{staticClass:"font-lg mb-5"},[_vm._v("Промокоды")]),_c('div',{staticClass:"profile-board mb-5"},[_c('v-tabs',{model:{value:(_vm.mainTab),callback:function ($$v) {_vm.mainTab=$$v},expression:"mainTab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"no-caps"},[_vm._v("Промокоды")])],1),_c('v-tabs-items',{model:{value:(_vm.mainTab),callback:function ($$v) {_vm.mainTab=$$v},expression:"mainTab"}},[_c('v-tab-item',[_c('h3',{staticClass:"font-md mt-4 mb-3"},[_vm._v("Настройка промокодов")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-center justify-space-between",attrs:{"_v-if":"$store.getters['auth/isAllowed']('add_manager')"}},[_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){_vm.showAll = !_vm.showAll}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" "+_vm._s(_vm.showAll ? 'Скрыть неактивные' : 'Показать все')+" ")],1),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary","small":"","_fab":"","outlined":""},on:{"click":function($event){return _vm.createPromocode()}}},[_vm._v(" Добавить промокод ")])],1)]),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headersCodes,"items":_vm.codesFiltered,"loading":_vm.loading.codes,"loading-text":"Загрузка... Пожалуйста подождите","mobile-breakpoint":300,"fixed-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","fab":"","small":"","href":item.url ? item.url : ("/promocode/" + (item.promocode)),"disabled":!item.url,"target":"_blank","color":"primary"}},[_c('v-icon',[_vm._v("mdi-link-variant")])],1)]}},{key:"item.is_default",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{staticClass:"ml-6",attrs:{"readonly":"","hide-details":""},model:{value:(item.is_default),callback:function ($$v) {_vm.$set(item, "is_default", $$v)},expression:"item.is_default"}})]}},{key:"item.usage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.promocode_users ? item.promocode_users.length : 0)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","fab":"","small":"","loading":_vm.loading.activate}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[(!_vm.isCodeActive(item))?_c('v-list-item',{on:{"click":function($event){return _vm.activate(item, true)}}},[_c('v-list-item-title',[_vm._v("Активировать")])],1):_vm._e(),(_vm.isCodeActive(item))?_c('v-list-item',{on:{"click":function($event){return _vm.activate(item, false)}}},[_c('v-list-item-title',[_vm._v("Деактивировать")])],1):_vm._e(),(!item.promocode_users || !item.promocode_users.length)?_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("Редактировать")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.del(item)}}},[_c('v-list-item-title',[_vm._v("Удалить")])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_vm._v(" Список промокодов пуст ")]},proxy:true}])})],1)],1)],1),_c('PromocodeEdit',{attrs:{"managedBy":_vm.userId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }