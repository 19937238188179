<template>
  <v-dialog v-model="dialogEditShow" max-width="700px">
    <v-card class="pa-4 pa-sm-8">
        <v-btn class="button-close ma-4" icon @click="dialogEditShow = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h2 class="text-center">{{editedItem.id ? 'Редактирование промокода' : 'Создание промокода'}}</h2>
      <form @submit.prevent="editConfirm" class="mt-6">
        <div class="container">

          <div class="row">
            <div class="col-md-6">
              <v-text-field label="Название" v-model="editedItem.title" @input="clearError()" :error-messages="titleErrors" outlined dense />
            </div>
            <div class="col-md-6">
              <v-text-field label="Промокод" v-model="editedItem.promocode" @input="clearError()" :error-messages="promocodeErrors" outlined dense />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <v-text-field label="Число тестов в пакете" v-mask="'###'" v-model="editedItem.package_size" @input="clearError()"
                :error-messages="package_sizeErrors" outlined dense />
            </div>
            <div class="col-md-6">
              <v-text-field label="Число использований" v-model="editedItem.usage" @input="clearError()" :error-messages="usageErrors" outlined dense />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <v-text-field label="Число тестов для распространяющего" v-mask="'###'" v-model="editedItem.tests_for_distributors" @input="clearError()"
                :error-messages="tests_for_distributersErrors" outlined dense />
            </div>
            <div class="col-md-6">
              <v-text-field label="Число тестов для покупающего" v-mask="'###'" v-model="editedItem.tests_for_users" @input="clearError()"
                :error-messages="tests_for_usersErrors" outlined dense />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <v-menu ref="menu_date_from" v-model="menu_date_from" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="date_fromFormatted" label="Дата начала" append-icon="mdi-calendar" readonly format="DD.MM.YYYY"
                        @change="$v.editedItem.date_from.$touch()"
                        @blur="$v.editedItem.date_from.$touch()"
                        :_hide-details="!date_fromErrors.length"
                        :error-messages="date_fromErrors"
                        v-bind="attrs" v-on="on" outlined dense
                    />
                </template>
                <v-date-picker
                  v-model="editedItem.date_from" scrollable locale="ru-RU" @input="menu_date_from=false" first-day-of-week=1>
                </v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-6">
              <v-menu ref="menu_date_to" v-model="menu_date_to" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="date_toFormatted" label="Дата окончания" append-icon="mdi-calendar" readonly format="DD.MM.YYYY"
                        @change="$v.editedItem.date_to.$touch()"
                        @blur="$v.editedItem.date_to.$touch()"
                        :_hide-details="!date_toErrors.length"
                        :error-messages="date_toErrors"
                        v-bind="attrs" v-on="on" outlined dense
                    />
                </template>
                <v-date-picker
                  v-model="editedItem.date_to" scrollable locale="ru-RU" @input="menu_date_to=false" first-day-of-week=1>
                </v-date-picker>
              </v-menu>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <v-text-field label="Ссылка" v-model="editedItem.url" @input="clearError()" outlined dense />
            </div>
            <div class="col-md-6">
              <v-switch v-model="editedItem.is_default" label="По умолчанию" hide-details dense class="mt-2" />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <v-text-field label="Описание" v-model="editedItem.description" @input="clearError()" outlined dense hide-details />
            </div>
          </div>

          <!-- <div v-for="(err, ind) in errors.edit" :key="ind" class="error--text">
            <template v-if="!editedItem.hasOwnProperty(err[0])">
              <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
            </template>
          </div> -->
        </div>

        <div class="row mt-3">
          <div class="col-md-6 mx-auto">
            <v-btn type="submit" class="w-100" color="primary" :loading="loading.save" :disabled="loading.save">
              Сохранить
            </v-btn>
          </div>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { maxLength, required, minValue } from 'vuelidate/lib/validators';
import { MESSAGES } from '@/common/constants/errorMessages';
import { VueMaskDirective } from 'v-mask';

export default {
  validations: {
    editedItem: {
      title: { required, maxLength: maxLength(80) },
      promocode: { required, maxLength: maxLength(80) },
      date_from: { required },
      date_to: {   },
      package_size: { required, minValue: minValue(1) },
      tests_for_distributors: { required, minValue: minValue(1) },
      tests_for_users: { required, minValue: minValue(1) },
      usage: {  },
    },
  },
  props: ['managedBy'],
  directives: {
    mask: VueMaskDirective,
  },
  data: () => ({
    editedItem: {
      id: 0,
      package_size: 0,
      tests_for_distributors: 0,
      tests_for_users: 0,
      managed_by: 0,
      // необязательные поля
      title: '',
      promocode: '',
      date_from: '',
      date_to: '',
      url: "",
      usage: 0,
      description: "",
      is_default: false
    },
    menu_date_from: false,
    menu_date_to: false,
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
        // this.editedItem = cloneDeep(this.editedCandidate);
        this.editedItem = (
          ({ id, package_size, tests_for_distributors, tests_for_users, managed_by, title, promocode, date_from, date_to, url, usage, description, is_default }) =>
          ({ id, package_size, tests_for_distributors, tests_for_users, managed_by, title, promocode, date_from, date_to, url, usage, description, is_default })
        )(this.editedPromocode);
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'profile/refs/loading',
      errors: 'profile/refs/errors',
      editedPromocode: 'profile/refs/editedPromocode',
    }),
    dialogEditShow: {
      get() {
        return this.$store.getters['profile/refs/dialogEditShow'];
      },
      set(val) {
        if (!val) {
          this.$v.$reset();
        }
        this.setDialogEditShow(val);
      },
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.editedItem.title.$dirty) return errors;
      if (!this.$v.editedItem.title.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.title.maxLength) errors.push(MESSAGES.MAX_LENGTH(80));
      return this.errors.edit.title ?? errors;
    },
    promocodeErrors() {
      const errors = [];
      if (!this.$v.editedItem.promocode.$dirty) return errors;
      if (!this.$v.editedItem.promocode.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.promocode.maxLength) errors.push(MESSAGES.MAX_LENGTH(80));
      return this.errors.edit.promocode ?? errors;
    },
    usageErrors() {
      const errors = [];
      if (!this.$v.editedItem.usage.$dirty) return errors;
      return this.errors.edit.usage ?? errors;
    },
    package_sizeErrors() {
      const errors = [];
      if (!this.$v.editedItem.package_size.$dirty) return errors;
      if (!this.$v.editedItem.package_size.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.package_size.minValue) errors.push(MESSAGES.MIN_VALUE(0));
      return this.errors.edit.package_size ?? errors;
    },
    tests_for_distributersErrors() {
      const errors = [];
      if (!this.$v.editedItem.tests_for_distributors.$dirty) return errors;
      if (!this.$v.editedItem.tests_for_distributors.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.tests_for_distributors.minValue) errors.push(MESSAGES.MIN_VALUE(0));
      return this.errors.edit.tests_for_distributors ?? errors;
    },
    tests_for_usersErrors() {
      const errors = [];
      if (!this.$v.editedItem.tests_for_users.$dirty) return errors;
      if (!this.$v.editedItem.tests_for_users.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.tests_for_users.minValue) errors.push(MESSAGES.MIN_VALUE(0));
      return this.errors.edit.tests_for_users ?? errors;
    },
    date_toErrors() {
      const errors = [];
      // if (!this.$v.editedItem.date_to.$dirty) return errors;
      return this.errors.edit.date_to ?? errors;
    },
    date_fromErrors() {
      const errors = [];
      if (!this.$v.editedItem.date_from.$dirty) return errors;
      if (!this.$v.editedItem.date_from.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.edit.date_from ?? errors;
    },
    date_fromFormatted() {
      return this.editedItem.date_from ? this.$moment(this.editedItem.date_from).format('DD.MM.YYYY') : '';
    },
    date_toFormatted() {
      return this.editedItem.date_to ? this.$moment(this.editedItem.date_to).format('DD.MM.YYYY') : '';
    },
  },
  methods: {
    ...mapActions({
      savePromocode: 'profile/refs/savePromocode',
    }),
    ...mapMutations({
      clearError: 'profile/refs/clearError',
      setDialogEditShow: 'profile/refs/setDialogEditShow',
    }),
    editConfirm() {
      if (this.loading.edit) return;
      this.$v.$touch();
      if (this.editedItem && !this.$v.$invalid) {
        const form = {
          ...this.editedItem,
          // managed_by: this.managedBy,
        };
        if (form.managed_by?.id) {
          form.managed_by = form.managed_by.id
        }

        if (!form.managed_by) {
          form.managed_by = this.managedBy
        }

        if (!form.date_from) form.date_from = null;
        if (!form.date_to) form.date_to = null;
        this.savePromocode(form).then((response) => {
          if (response) {
            this.dialogEditShow = false;
            console.log('dialogEditShow', this.dialogEditShow);
          } else {
            console.log(this.$v);
            this.$v.$touch();
          }

        });
      }
    },
  },
};

</script>
