<template>
  <div>
    <div v-if="loading.codes" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <BackBtn />
    <BreadCrumbs :items="breadcrumbs" />

    <h1 class="font-lg mb-5">Промокоды</h1>

    <div class="profile-board mb-5">

      <v-tabs v-model="mainTab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab class="no-caps">Промокоды</v-tab>
      </v-tabs>
      <v-tabs-items v-model="mainTab">
        <v-tab-item >
          <h3 class="font-md mt-4 mb-3">Настройка промокодов</h3>
          <div class="row">
            <div class="col-12 d-flex align-center justify-space-between" _v-if="$store.getters['auth/isAllowed']('add_manager')">
              <v-btn class="" color="primary" small outlined @click="showAll = !showAll">
                <v-icon small class="mr-2">mdi-eye-outline</v-icon> {{ showAll ? 'Скрыть неактивные' : 'Показать все' }}
              </v-btn>
              <v-btn class="ml-auto" color="primary" small _fab outlined @click="createPromocode()">
                <!-- <v-icon small class="mr-2">mdi-tab-plus</v-icon>  -->
                Добавить промокод
              </v-btn>
            </div>
          </div>
          <v-data-table :headers="headersCodes" :items="codesFiltered"
                    class="mt-4"
                    :loading="loading.codes" loading-text="Загрузка... Пожалуйста подождите"
                    :mobile-breakpoint="300" fixed-header hide-default-footer :items-per-page="-1"
          >
            <template v-slot:item.url="{ item }">
              <v-btn text fab small :href="item.url ? item.url : `/promocode/${item.promocode}`" :disabled="!item.url" target="_blank" color="primary">
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.is_default="{ item }">
              <v-checkbox class="ml-6" v-model="item.is_default" readonly hide-details />
            </template>

            <template v-slot:item.usage="{ item }">
              {{ item.promocode_users ? item.promocode_users.length : 0}}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text fab small v-bind="attrs" v-on="on" :loading="loading.activate"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-if="!isCodeActive(item)" @click="activate(item, true)"><v-list-item-title>Активировать</v-list-item-title></v-list-item>
                      <v-list-item v-if="isCodeActive(item)" @click="activate(item, false)"><v-list-item-title>Деактивировать</v-list-item-title></v-list-item>
                      <v-list-item v-if="!item.promocode_users || !item.promocode_users.length" @click="edit(item)"><v-list-item-title>Редактировать</v-list-item-title></v-list-item>
                      <v-list-item @click="del(item)"><v-list-item-title>Удалить</v-list-item-title></v-list-item>
                    </v-list>
                  </v-menu>
            </template>
            <template v-slot:no-data>
              Список промокодов пуст
            </template>
          </v-data-table>

        </v-tab-item>

      </v-tabs-items>
    </div>
    <PromocodeEdit :managedBy="userId" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { PARTNER, ADMIN } from '@/common/constants/userTypes';

import PromocodeEdit from './PromocodeEdit.vue';

export default {
  name: 'PromocodesPage',
  components: {
    PromocodeEdit
  },
  props: ['userId', 'userType'],
  inject: {
    isProfile: {
      default: false
    }
  },
  data() {
    return {
      mainTab: false,
      showAll: true,
      headersCodes: [
        { text: 'Название', value: 'title', },
        { text: 'Ссылка', value: 'url', },
        { text: 'Код', value: 'promocode', },
        { text: 'Число тестов в пакете', value: 'package_size', sortable: false, },
        { text: 'Число тестов для распространяющего', value: 'tests_for_distributors', sortable: false, },
        { text: 'Число тестов для покупающего', value: 'tests_for_users', sortable: false, },
        {
          text: 'Дата начала', align: 'center', value: 'date_from', sortable: false,
        },
        {
          text: 'Дата окончания', align: 'center', value: 'date_to', sortable: false,
        },
        {
          text: 'Число активаций', align: 'center', value: 'usage', sortable: false,
        },
        {
          text: 'По умолчанию', align: 'center', value: 'is_default', sortable: false,
        },
        {
          text: '', value: 'actions', sortable: false, align: 'center',
        },
      ],

    };
  },
  computed: {
    ...mapGetters({
      partner: 'users/partners/partner',
      admin: 'users/admins/admin',
      codes: 'profile/refs/codes',
      defaultRef: 'profile/refs/defaultRef',
      loading: 'profile/refs/loading',
      user: 'auth/user',
      // isCodeActive: 'profile/refs/isCodeActive',
    }),
    codesFiltered() {
      return this.showAll ? this.codes : this.codes.filter(code => this.isCodeActive(code));
    },
    breadcrumbs() {
      if (this.userType === ADMIN) {
        return [
          { title: 'Личный кабинет', url: '/' },
          { title: 'Промокоды', url: '/promocodes' },
        ];
      }
      if (this.userType === PARTNER) {
        if (!this.partner) return [];
        return this.isProfile ? [
          { title: 'Личный кабинет', url: '/' },
          { title: 'Промокоды', url: '/promocodes' },
        ] : [
          { title: 'Партнеры', url: '/partners/' },
          { title: `${this.partner.last_name} ${this.partner.first_name}`, url: `/partners/${this.partner.id}` },
          { title: 'Промокоды', url: `/partners/${this.partner.id}/promocodes` },
        ];
      }
      return [{ title: 'Ошибка', url: '/' }];
    },

  },
  methods: {
    ...mapActions({
      loadPartner: 'users/partners/loadPartner',
      loadCodes: 'profile/refs/loadCodes',
      setCodeActve: 'profile/refs/setCodeActve',
      deletePromocode: 'profile/refs/deletePromocode',
    }),
    ...mapMutations({
      setEditedPromocode: 'profile/refs/setEditedPromocode',
      setDialogEditShow: 'profile/refs/setDialogEditShow',
    }),

    isCodeActive(code) {
      return !(code.date_to && this.$moment(code.date_to, 'YYYY-MM-DD').isBefore());
    },
    async activate(item, active) {
      await this.setCodeActve({ code: item, active });
    },
    async del(item) {
      await this.deletePromocode(item);
    },
    async edit(item) {
      this.setEditedPromocode(item);
      this.setDialogEditShow(true);
    },
    createPromocode() {
      this.setEditedPromocode(null);
      this.setDialogEditShow(true);
    },
    async load() {
      await this.loadCodes({ user_type: this.userType, id: this.userId });
      if (this.userType === PARTNER) {
        await this.loadPartner(this.userId);
      }
    }
  },
  async created() {
    await this.load();
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
</style>
