<template>
    <div class="page">
      <PartnerPromocodes :userId="$route.params.id" userType='Partner'/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PartnerPromocodes from '@/components/partners/profile/PartnerPromocodes';

export default {
  name: 'PartnerPromocodesPage',
  components: {
    PartnerPromocodes,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
